<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <!-- <v-layout wrap> -->
    <v-layout wrap>
      <v-flex xs12 pt-4>
        <v-card elevation="0">
          <v-form v-model="addgrade" ref="addgrade">
            <v-layout>
              <v-flex xs6 text-left>
                <span class="headline">Gallery Image</span>
              </v-flex>
              <v-flex xs6 text-right>
                <v-btn
                  color="green"
                  outlined
                  small
                  @click="$router.push('/EventsPage')"
                  >Event List
                  <v-icon small>mdi-arrow-left</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout justify-center wrap style="border-bottom: 1px dashed">
              <!-----------------upload gallery image------------------>
              <v-flex xs10>
                <v-layout wrap pt-1 align-center>
                  <v-flex xs10 md6 lg12 align-self-center text-center pb-2>
                    <v-card outlined>
                      <v-icon
                        size="25px"
                        id="pro_pic3"
                        @click="$refs.files4.click()"
                        >mdi-plus</v-icon
                      >
                      <span
                        style="
                          color: #828282;
                          font-family: montserratLight;
                          font-size: 14px;
                        "
                        >Upload Gallery Image</span
                      >
                      <input
                        v-show="false"
                        accept="image/*"
                        id="file4"
                        ref="files4"
                        multiple="multiple"
                        type="file"
                        @change="uploadImages3"
                      />
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs2>
                <v-btn
                  color="blue darken-1"
                  text
                  :disabled="!addgrade"
                  @click="uploadAllImages3()"
                >
                  Save
                </v-btn>
              </v-flex>
              <v-flex
                xs12
                sm3
                v-for="(item, i) in galleryimageArray"
                :key="i"
                pa-1
              >
              <v-card>
               
                <v-img :src="item" height="50px" contain>
                  <v-layout wrap>
                    <v-flex text-right pa-0>
                      <v-avatar color="#FF3434" size="15">
                        <v-icon color="#FFF" small @click="uploadAllImages3(i)">
                          mdi-close
                        </v-icon>
                      </v-avatar>
                    </v-flex>
                  </v-layout>
                </v-img>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12 v-if="galleryList">
                <v-layout wrap justify-center v-if="galleryList.length > 0">
                  <v-flex xs3 pa-2 v-for="(item, i) in galleryList" :key="i">
                <!-- <v-badge
                  icon="mdi-check-circle-outline"
                  overlap
                  background-color="red"
                >
                  <template slot="badge">
                    <v-icon
                      @click="removeImageArray(null, index)"
                      small
                      pa-0
                      py-0
                      px-0
                      color="#FFFFFF"
                      style="font-size: 15px; text-align: right"
                      >mdi-close-circle-outline</v-icon
                    >
                  </template>    -->
                     <v-card tile>
                      <v-img contain :src="mediaURLnews + item" 
                    /></v-card>
                    <v-flex xs12>
                      <v-btn small outlined @click="removeImageArray1(i)">Delete</v-btn>
                    </v-flex>
                <!-- </v-badge> -->
                  </v-flex>
                   </v-layout>
                  <v-layout v-else wrap>
                    <v-flex xs2 align-self-center>No images found</v-flex>
                  </v-layout>
               
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="cropGalleryImageDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : $vuetify.breakpoint.name == 'md'
          ? '80vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '50vw'
          : '40vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageCropper
              :image="currentGalleryImage"
              :key="currentGalleryImage"
              @stepper="imageCropper3"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deletedialogue" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          small
          pa-0
          py-0
          px-0
          v-on="on"
          v-bind="attrs"
          color="#FFFFFF"
          style="font-size: 15px"
          >mdi-trash-can-outline</v-icon
        >
      </template>
      <v-card>
        <v-card-title>Are you sure you want to delete this Photo?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deletedialogue = false"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="removeImageArray(pageId, index - 1)"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- </v-layout> -->
  </div>
</template>


<script>
import axios from "axios";
import ImageCropper from "./../Common/imageCropper";

// import store from "./../../store";
export default {
  components: {
    ImageCropper,
  },
  data() {
    return {
      msg: "",
      dialog: false,
      addgrade: false,
      documentFile1: [],
      timeout: 5000,
      count: "",
      snackbar: false,
      appLoading: false,
      showSnackBar: false,
      todate: null,
      menu2: false,
      page: 1,
      Pagelength: 0,
      deletedialogue: false,
      dialoge: false,
      //upload image
      galleryimageArray: [],
      Images3: new FormData(),
      cropGalleryImageDialog: false,
      currentGalleryImage: null,
      selectedFiles: null,
      selectedFiles2: null,
      selectedFiles3: null,
      image: null,
      galleryList: [],
      id1: this.$route.query.id,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
         removeImageArray1(index) {
          var data = {};
          data["position"] = index;
          data["id"] = this.id1;

          axios({
            method: "post",
            url: "/event/removeGallery",
            data: data,
            headers: {
              "token": localStorage.getItem("token"),
            },
          }).then((response) => {
            if (response.data.status) {
             this.getData();
            }
          });
    },
     removeImageArray(value, index) {
      console.log("id=",this.$router.query.id)
      if (value != null) {
          var data = {};
          data["position"] = index + 1;
          data["id"] = this.$router.query.id;

          axios({
            method: "post",
            url: "/event/removeGallery",
            data: data,
            headers: {
              "token": localStorage.getItem("token"),
            },
          }).then((response) => {
            if (response.data.status) {
              this.$emit("stepper", {
                type: "imageDeletion",
                response: response.data,
              });
              this.delete=false
            }
          });
      } 
      else {
        this.imageArray.splice(index, 1);
        this.$emit("stepper", {
          type: "imageRemoval",
          imageArray: this.imageArray,
          removalItem: index,
        });
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/media/admin/event/view",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          page: this.page,
          limit: 15,
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.galleryList = response.data.data.gallery;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    // gallery image upload
    imageCropper3(payload) {
      this.cropGalleryImageDialog = payload.dialog;
      if (payload.image) {
        this.collectImages3(payload.image);
      }
    },
    uploadImages3(event) {
      console.log("upload image3");
      if (this.galleryimageArray.length < 10) {
        this.currentGalleryImage = URL.createObjectURL(event.target.files[0]);
        this.cropGalleryImageDialog = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    collectImages3(image) {
      this.selectedFiles3 = image;
      this.Images3.append("galleryImage", this.selectedFiles3);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.galleryimageArray.push(urls);
        if (this.galleryimageArray) {
          this.image = this.galleryimageArray[0];
        }
      };
      console.log("image3=", this.selectedFiles3);
    },
    //upload image3
    uploadAllImages3() {
      this.appLoading = true;
      this.Images3.append("id", this.$route.query.id);
      axios({
        method: "POST",
        url: "/media/upload/event/images/gallery",
        data: this.Images3,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.Images3 = null;
            this.galleryimageArray = [];
            // window.location.reload();
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
            // this.$router.push("/Seller/Product/Added/" + item);
          } else {
            this.Images3 = new FormData();
            this.galleryimageArray = [];
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //
  },
};
</script>







